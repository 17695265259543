/* eslint-disable react-hooks/exhaustive-deps */
'use client' // Error components must be Client Components
import { useEffect } from 'react'
import { cookie } from '@/utils'
import { useSession } from '@/hooks'

export default function Error({ error }: {
  error: Error & { digest?: string }
}) {
  const { setUser } = useSession()

  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  useEffect(() => {
    setUser(null)
    cookie.remove('DY_VIRBAC_USER')
  }, [])

  return (
    <div className='global-err'>
      <hgroup>
        <h1>La página que buscas no existe</h1>
        <p>Puedes <a href={`https://${process.env.NEXT_PUBLIC_PROJECT_HOSTNAME}`}>volver al inicio</a></p>
      </hgroup>
    </div>
  )
}
